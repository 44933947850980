<template>
  <v-container id="change-password">
    <div v-if="isSala">
      <v-col class="margem">
        <v-col class="text-center">
          <h1>Primeiro Acesso</h1>
        </v-col>
        <div class="text-center">
          Bem vindo, cadastre sua senha abaixo para fazer login ao sistema.
        </div>
      </v-col>

      <login-selo v-if="isSala">
        <template v-slot:formulario>
          <FirstAccessComponents></FirstAccessComponents>
        </template>
      </login-selo>
    </div>
    <v-row v-else>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <v-col class="text-center">
          <h1>Primeiro Acesso</h1>
        </v-col>
        <div>
          Bem vindo, cadastre sua senha abaixo para fazer login ao sistema.
        </div>
        <FirstAccessComponents></FirstAccessComponents>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import LoginSelo from "./LoginComponents/LoginSelo";
import FirstAccessComponents from "./firstAcessComponents/Form";

export default {
  components: { LoginSelo, FirstAccessComponents },
  computed: {
    ...mapGetters(["isSala"]),
  },
};
</script>
<style lang="scss" scoped>
.falta {
  color: red;
}
</style>
