export const regexSenhaQuebrado = {
    letraMinuscula: new RegExp(/(?=.*[a-z])/),
    letraMaiuscula: new RegExp(/(?=.*[A-Z])/),
    numero: new RegExp(/(?=.*[0-9])/),
    especial: new RegExp(/(?=.*[!@#.\$%\^&\*])/),
    totalRegex: new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#.\$%\^&\*]).{8,}$/
    )
}

export const especificacaoLabels = [
    { name: "caracteres", title: "Mínimo de 8 caracteres" },
    { name: "minuscula", title: "Pelo menos uma letra minúscula" },
    { name: "maiuscula", title: "Pelo menos uma letra maiúscula" },
    { name: "numero", title: "Pelo menos um número" },
    { name: "especial", title: "Pelo menos um caracter especial: !@#." },
]