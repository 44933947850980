import baseAPI from '@/http'

const router = 'api/usuario/usuario'

export const userAPI = {
  create: (user) => baseAPI.post(router, user),
  recuperarSenha: (user) => baseAPI.post(`${router}/recuperar-senha`, user),
  trocarSenha: (user) => baseAPI.post(`${router}/trocar-senha`, user),

}
