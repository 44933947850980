<template>
  <v-col class="width">
    <v-card class="elevation-12 degrade-background-sebrae cardzao">
      <div class="logo">
        Painel de Indicadores <br />
        Selo Sebrae de Referência em Atendimento
      </div>
      <div class="cardAqui">
        <div class="medalhas left">
          <img
            src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/sombra/seloMedalhaBronzeSombra.png"
          />
          <img
            src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/sombra/seloMedalhaPrataSombra.png"
          />
        </div>
        <div class="medalhas right">
          <img
            src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/sombra/seloMedalhaOuroSombra.png"
          />
          <img
            src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/sombra/seloMedalhaDiamanteSombra.png"
          />
        </div>
        <div class="formulario">
          <slot name="formulario"></slot>
        </div>
      </div>
    </v-card>
  </v-col>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/variables.scss";

.cardzao {
  min-height: 400px;
  min-width: 650px;
}
.width {
  max-width: 650px;
  margin: 0 auto;
}

.formulario {
  margin: 0 auto;
  width: 60%;
}

.cardAqui {
  position: relative;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $cor-selo-texto;
  font-size: 25px;
  font-weight: bold;
  padding: 2rem 1rem 1rem 1rem;
  text-align: center;
}

.logoImg {
  width: 350px;
  margin-top: 20px;
}

.medalhas {
  position: absolute;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 130px;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

@media screen and (max-width: 770px) {
  .logo {
    font-size: 15px;
  }
  .cardzao {
    min-height: 400px;
    min-width: 100%;
  }
  .width {
    max-width: 100%;
    margin: 0;
  }

  .formulario {
    margin: 0 auto;
    width: 90%;
  }
}
</style>
