<template>
  <v-form lazy-validation @submit.prevent="onSubmit">
    <v-text-field
      v-if="!showNewPassword"
      id="password"
      label="Nova Senha"
      name="password"
      type="password"
      v-model="payload.senha"
      :rules="passwordRules"
      required
      append-icon="mdi-eye-off"
      background-color="white"
      solo
      @click:append="showNewPassword = true"
    ></v-text-field>
    <v-text-field
      v-if="showNewPassword"
      id="password"
      label="Nova Senha"
      name="password"
      type="text"
      v-model="payload.senha"
      :rules="passwordRules"
      required
      @input="checkCaracteres"
      append-icon="mdi-eye"
      background-color="white"
      solo
      @click:append="showNewPassword = false"
    ></v-text-field>

    <v-text-field
      v-if="!showConfirmPassword"
      id="confirm-password"
      label="Confirmação de Senha"
      name="confirm-password"
      type="password"
      v-model="payload.confirmacaoSenha"
      :rules="confirmPasswordRules"
      required
      @input="checkCaracteres"
      append-icon="mdi-eye-off"
      background-color="white"
      solo
      @click:append="showConfirmPassword = true"
    ></v-text-field>
    <v-text-field
      v-if="showConfirmPassword"
      id="confirm-password"
      label="Confirmação de Senha"
      name="confirm-password"
      type="text"
      v-model="payload.confirmacaoSenha"
      :rules="confirmPasswordRules"
      required
      @input="checkCaracteres"
      append-icon="mdi-eye"
      background-color="white"
      solo
      @click:append="showConfirmPassword = false"
    ></v-text-field>
    <div class="mt-5">
      <div
        v-for="(especificacao, index) in especificacaoSenha"
        :key="index"
        :class="
          errosSenha.includes(especificacao.name) ? 'falta' : '' + 'espec'
        "
      >
        -
        {{ especificacao.title }}
      </div>
    </div>
    <v-row>
      <v-col>
        <div class="text-center mt-2">
          <v-btn color="primary" type="submit"> Cadastrar Senha </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  regexSenhaQuebrado,
  especificacaoLabels,
} from "@/helpers/senhaEspecificacao";
import { userAPI } from "@/modules/user";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      payload: {},
      especificacaoSenha: especificacaoLabels,
      errosSenha: [],
      token: this.$route.query.token,
      passwordRules: [
        (v) => !!v || "O campo nova senha é obrigatório",
        (v) => {
          if (v && !this.regexSenhaQuebrado.totalRegex.test(v)) {
            this.errosSenha = [];
            !this.regexSenhaQuebrado.letraMinuscula.test(v) &&
              this.errosSenha.push("minuscula");
            !this.regexSenhaQuebrado.letraMaiuscula.test(v) &&
              this.errosSenha.push("maiuscula");
            !this.regexSenhaQuebrado.numero.test(v) &&
              this.errosSenha.push("numero");
            !this.regexSenhaQuebrado.especial.test(v) &&
              this.errosSenha.push("especial");
            v.length < 8 && this.errosSenha.push("caracteres");

            return "Senha fraca";
          }
          this.errosSenha = [];
          return true;
        },
      ],
      regexSenhaQuebrado: regexSenhaQuebrado,
      confirmPasswordRules: [
        (v) => !!v || "O campo confirmação de senha é obrigatório",
        (v) => {
          if (v !== this.payload.senha) {
            return "As senhas devem ser iguais";
          }
          return true;
        },
      ],
      showNewPassword: false,
      showConfirmPassword: false,
      valid: true,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSubmit() {
      if (!this.payload.senha || !this.payload.confirmacaoSenha) {
        this.$notification.error(
          "Os campos senha e confirmação de senha são obrigatórios!",
          { timer: 10 }
        );
      } else {
        this.setLoading(true);
        this.payload = {
          senha: this.payload.senha,
          tokenPrimeiroAcesso: this.token,
        };

        userAPI
          .trocarSenhaPrimeiroAcesso(this.payload)
          .then(({ data }) => {
            const verify = data;
            this.setLoading(false);
            if (!verify) {
              this.$notification.error(
                "Esse token já foi utilizado, por favor faça login",
                {
                  timer: 10,
                }
              );
            } else {
              this.$notification.success("Senha atualizada com sucesso!", {
                timer: 10,
              });
            }

            this.$router.push("/auth/login");
          })
          .catch((resp) => {
            this.setLoading(false);
            this.$notification.error("Erro ao atualizar senha!", { timer: 10 });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.falta {
  color: red;
}

@media screen and (max-width: 770px) {
  .espec {
    z-index: 1;
    position: relative;
    text-shadow: 1px 2px 6px #000000;
    text-align: center;
  }
}
</style>
